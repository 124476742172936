export default [
  {
    component: 'CNavItem',
    name: 'Verfügbarkeitscheck',
    to: '/verfuegbarkeitscheck',
    icon: 'cil-search',
    allowed: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_EDITOR'],
    sidebar: true,
    sidebarfooter: false,
    header: true,
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        sidebar: true,
        sidebarfooter: false,
        header: true,
        to: '/verfuegbarkeitscheck/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Extern Vorschau',
        sidebar: true,
        sidebarfooter: false,
        header: true,
        to: '/verfuegbarkeitscheck/extern'
      },
      {
        component: 'CNavItem',
        name: 'Extern Transparent',
        sidebar: true,
        sidebarfooter: false,
        header: true,
        to: '/verfuegbarkeitscheck/extern-transparent'
      },
      {
        component: 'CNavItem',
        name: 'Iframe Vorschau',
        sidebar: true,
        sidebarfooter: false,
        header: true,
        to: '/verfuegbarkeitscheck/iframe'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Adressen',
    to: '/adressen',
    icon: 'cil-address-book',
    header: true,
    sidebar: true,
    allowed: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_EDITOR'],
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        header: true,
        sidebar: true,
        to: '/adressen/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Neue Adressen',
        header: true,
        sidebar: true,
        to: '/adressen/neu'
      },
      {
        component: 'CNavItem',
        name: 'Importieren',
        header: true,
        sidebar: true,
        to: '/adressen/importieren'
      },
      {
        component: 'CNavItem',
        name: 'Exportieren',
        header: true,
        sidebar: true,
        to: '/adressen/exportieren'
      },
      {
        component: 'CNavItem',
        name: 'Logs',
        header: true,
        sidebar: true,
        to: '/adressen/logs'
      },
      {
        component: 'CNavItem',
        name: 'Übersicht (Test)',
        header: true,
        sidebar: true,
        to: '/adressen/test'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Bestellstrecken',
    to: '/shop-links',
    icon: 'cil-cart',
    allowed: ['ROLE_ADMIN'],
    sidebar: true,
    sidebarfooter: false,
    header: true,
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        sidebar: true,
        sidebarfooter: false,
        header: true,
        to: '/shop-links/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Neue Bestellstrecke',
        sidebar: true,
        sidebarfooter: false,
        header: true,
        to: '/shop-links/neu'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Tarife & Optionen',
    to: '/produkt',
    header: true,
    sidebar: true,
    icon: 'cil-speedometer',
    allowed: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_EDITOR'],
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        header: true,
        sidebar: true,
        to: '/produkt/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Neuer Tarif/Option',
        header: true,
        sidebar: true,
        to: '/produkt/neu'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Texte',
    to: '/texte',
    icon: 'cil-info',
    header: false,
    sidebar: false,
    sidebarfooter: false,
    allowed: ['ROLE_ADMIN'],
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        header: false,
        sidebar: true,
        sidebarfooter: false,
        to: '/texte/uebersicht'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Konfiguration',
    to: '/konfiguration',
    icon: 'cil-info',
    header: false,
    sidebar: true,
    sidebarfooter: true,
    allowed: ['ROLE_ADMIN'],
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        header: false,
        sidebar: true,
        sidebarfooter: true,
        to: '/konfiguration/uebersicht'
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Benutzer',
    to: '/personal',
    icon: 'cil-group',
    allowed: ['ROLE_ADMIN'],
    sidebar: true,
    sidebarfooter: true,
    header: false,
    items: [
      {
        component: 'CNavItem',
        name: 'Übersicht',
        sidebar: true,
        sidebarfooter: true,
        header: false,
        to: '/benutzer/uebersicht'
      },
      {
        component: 'CNavItem',
        name: 'Neuer Benutzer',
        sidebarfooter: true,
        sidebar: true,
        header: false,
        to: '/benutzer/neu'
      }
    ]
  }
]
